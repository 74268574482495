<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap">
          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">支付时间: </span>
            <div class="width300">
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="~"
                  @change="handleDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>

          <el-input size="medium" v-model="searchData.name" placeholder="姓名" :maxlength="100" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.phone" placeholder="手机号" :maxlength="100" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.houseCode" placeholder="房源号/楼盘号" :maxlength="100" class="marginRight10 width200 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>


        <div class="flex align-items marginBottom10 flex-wrap">
          <span class="span marginRight20">支付金额：￥{{ total.money }}</span>
          <span class="span">支付数量：{{ total.num }}</span>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { list, count } from "@/api/finance/manage";
import qs from "qs";
export default {
  name: "index",
  data() {
    return {
      total: {
        money: 0,
        num: 0
      },
      date: [],
      searchData: {
        name: '',
        phone: '',
        houseCode: '',
        payTimeStart: '',
        payTimeEnd: ''
      },
      page: {
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '财务管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: '序号',
        indexWidth: 100,
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        menu: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '分账时间',
            prop: 'createTime',
            width: 200
          },
          {
            label: '交易单号',
            prop: 'signCode',
            width: 150
          },
          {
            label: '房源号/楼盘号',
            prop: 'houseCode',
            width: 250
          },
          {
            label: '房源名称/楼盘名称',
            prop: 'houseName',
            width: 250
          },
          {
            label: '费用单号',
            prop: 'code',
            width: 200
          },
          {
            label: '支付类型',
            prop: 'payType',
            type: 'radio',
            dicData: [
              {
                label: '线下支付',
                value: 1
              },
              {
                label: '线上支付',
                value: 2
              },
              {
                label: '线上支付 + 线下支付',
                value: 3
              }
            ],
            width: 150
          },
          {
            label: '业务类型',
            prop: 'type',
            type: "radio",
            dicData: [
              { label: '二手房', value: 1 },
              { label: '办公租赁', value: 2 },
              { label: '新房', value: 3 }
            ],
            width: 150
          },
          {
            label: '支付费用项',
            prop: 'payItem',
            type: 'radio',
            dicData: [
              { label: '服务费', value: 1 },
              { label: '佣金', value: 2 },
              { label: '渠道费', value: 3 }
            ],
            width: 150
          },
          {
            label: '支付时间',
            prop: 'payTime',
            width: 200
          },
          {
            label: '支付金额',
            prop: 'sumMoney',
            width: 150
          },
          {
            label: '录入人/申请人姓名',
            prop: 'applyUsername',
            width: 200
          },
          {
            label: '录入人/申请人手机号',
            prop: 'applyPhone',
            width: 200
          },
          {
            label: '录入人/申请人分账金额',
            prop: 'applyMoney',
            width: 200
          },
          {
            label: '办理人姓名',
            prop: 'handleUsername',
            width:150
          },
          {
            label: '办理人手机号',
            prop: 'handlePhone',
            width:150
          },
          {
            label: '办理人分账金额',
            prop: 'handleMoney',
            width:150
          },
          {
            label: '平台分账金额',
            prop: 'platformMoney',
            width:150
          },
          {
            label: '支付方分账金额',
            prop: 'payMoney',
            width:150
          }
        ]
      }
    }
  },
  mounted() {
    this.getCount()
  },
  methods: {
    async getCount(){
      const { data } = await count({
        ...this.searchData
      });
      console.log(data)
      this.total = {
        ...data
      }
    },
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 选择支付时间
    handleDate(val){
      console.log(val)
      if(val.length > 0){
        this.searchData.payTimeStart = val[0] + ' 00:00:00'
        this.searchData.payTimeEnd = val[1] + ' 23:59:59'
      }else{
        this.searchData.payTimeStart = '';
        this.searchData.payTimeEnd = ''
      }
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
      this.getCount();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        name: '',
        phone: '',
        houseCode: '',
        payTimeStart: '',
        payTimeEnd: ''
      }
      this.date = [];
      this.page.currentPage = 1;
      this.onLoad();
      this.getCount();
    },
    // 导出
    handleExport(){
      // console.log(qs.stringify(this.searchData))
      window.open(`/api/web/finance/manage/export?${qs.stringify(this.searchData)}`,'_self');
    }
  }
}
</script>

<style scoped>

</style>