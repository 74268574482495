import {get} from "@/api/axios";

/**
 * /web/finance/manage/list get
 */
export const list = params => get('/web/finance/manage/list', params)

/**
 * /web/finance/manage/count get
 */
export const count = params => get('/web/finance/manage/count', params)